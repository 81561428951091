@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'phone.css';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin: 0;
  font-size: 14px;
}

.unsplash-react {
  overflow: auto;
  padding-bottom: 36px !important;
}

.unsplash-react > :nth-child(2) {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.loading-spinner {
  height: 80px;
  width: 80px;
  border: 10px solid #0fc2cc6b;
  border-top: 10px solid #12c1cb;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay-background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  transition: all 0.25s ease-in;
}

.file-uploader .file,
.file-uploader .upload {
  display: inline-block;
  vertical-align: top;
  padding: 10px 0;
  border: 1px solid lightgray;
}

.file-uploader .file {
  width: 210px;
  border-radius: 0 2px 2px 0;
  border-right: none;
  color: black;
  background-color: white;
  cursor: default;
}

.file-selector {
  visibility: hidden;
  width: 0;
}

.file-uploader .upload {
  width: 98px;
  border-radius: 2px 0 0 2px;
  border-left: none;
  color: gray;
  background-color: lightgray;
  cursor: pointer;
}

.photo-cropper-overlay {
  position: fixed;
  top: 70px;
  left: 50vw;
  width: 440px;
  margin-left: -240px;
  padding: 20px;
  border-radius: 3px;
  background-color: white;
  text-align: center;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 1999;
  border-radius: 10px;
}

.crop-submit {
  display: inline-block;
  width: 220px;
  padding: 10px;
  border-radius: 30px;
  background: #08324c;
  color: white;
  text-align: center;
  cursor: pointer;
  margin: 0;
  margin-top: 10px;
}

.crop-cancel {
  display: inline-block;
  margin: 20px auto;
  cursor: pointer;
  color: #444;
}

.loading-box {
  width: 400px;
  height: 400px;
  margin: 20px auto;
  border: 1px solid black;
  text-align: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-box img {
  animation: rotation 10s infinite linear;
  margin-top: 75px;
  width: 200px;
}
